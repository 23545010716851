import './mapStyle.css';
import Map from './Map';
import Logo from './logo.png';

function App() {
  return (
    <>
      <div className='red-bg text-center text-light py-5 px-2'>
        <img src={Logo} width="100px" className='mb-4'/>
        <p class="m-0">Eine</p>
        <h1 class="mt-0" style={{fontSize: "90px", lineHeight: "1em"}}>U-Bahn</h1>
        <p class="m-0">für Wilhelmshaven</p>
        <p className='mt-5'>
          Eine repräsentative Umfrage der sehr guten Partei <b>Die PARTEI</b>, hat ergeben, dass die Wilhelmshavener*innen eine U-Bahn wollen! Dies sind unsere Zukunftspläne. 
        </p>
      </div>
      <div class='gradient gradient1'>
        <svg viewBox='0 0 100 50' preserveAspectRatio='none' overflow="visible" className=''>
          <polygon points="0,0 100,0 100,50" className="main shadow"/>
        </svg>
      </div>
      <Map />
    </>
  );
}

export default App;
