import { useState } from 'react';
import './mapStyle.css';
import { Col, Row } from 'react-bootstrap';
import ehrenwortplatz from './images/ehrenwortplatz.jpg';
import friesenstube from './images/friesenstube.jpg';
import stadthalle from './images/stadthalle.jpg';
import wagnerloch from './images/wagnerloch.png';
import bunker from './images/bunker.jpg';

export default function Map() {

    const [selection, setSelection] = useState("")

    const content = [
        {
            name: "Ehrenwortplatz",
            text: "Der berühmte Ehrenwortplatz wird zum Hauptbahnhof der neuen U-Bahn. Also keine Sorge, daneben kommt nur der Eingang hin, der schöne leere abgezäunte Platz bleibt natürlich.",
            img: ehrenwortplatz
        },
        {
            name: "Wagner Loch",
            text: "Bewundern sie den Bau des teuersten Baggersees Deutschland",
            img: wagnerloch
        },
        {
            name: "Neue Stadthalle",
            text: "Schauen sie sich den Platz an, der schon bald plattgemacht wird um dort eine Stadthalle zu errichten ohne die Wilhelmshaven gefühlt nicht existieren kann.",
            img: stadthalle
        },
        {
            name: "U-Boot Bahnhof",
            text: "Zusätzlich zur U-Bahn planen wir einen U-Boot Bahnhof auf dem Rüstringer Berg. Dieser wird eine Touristenattraktion werden, wenn Wilhelmshaven irgendwann untergeganen ist.",
            img: null
        },
        {
            name: "Bunker",
            text: "Bestauenen sie diesen wunderschönen Klotz, der sicher bald™ irgendwann mal ganz sicher und garantiert das Trilaterale Wattenmeer-Dings beherbergen wird",
            img: bunker
        },
        {
            name: "Friesenstube",
            text: "Wenn man auf der Strecke nochmal Durst bekommt. (Oder bei einer sehr guten PARTEI vorbei schauen möchte.)",
            img: friesenstube
        },
        {
            name: "F'Groden",
            text: "Joa... Keine Ahnung... Wir sind uns auch nicht sicher ob sich hier eine Haltestation lohnt.",
            img: null
        },
        {
            name: "Ewige Baustelle",
            text: "Jedes Projekt kostet am Ende viel mehr als Anfangs gedacht. Wir vermuten, dass dies auch bei unserer U-Bahn der Fall sein wird und planen daher schon im Vorfeld ein, dass die Strecke nach F'Groden vermutlich erst 30 Jahre später fertig wird als der Rest der U-Bahn, bis wir das Geld dafür zusammen haben. Ist auch kein großer Verlust.",
            img: null
        },
        {
            name: "Tunnel durch den Jadebusen",
            text: "Hier führt die U-Bahn durch einen Tunnel, unter dem Jadebusen hindurch in den Osten. Ob nach Grosswürden oder nach Tossens, hängt davon ab welche Ort sich mit mehr Geld an der U-Bahn beteiligt.",
            img: null
        },
        {
            name: "",
            text: "",
            img: null
        },
    ]

    return (
        <div className="App">
            <Row>
                <Col>
                        <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns-xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 1080 1080" xml-space="preserve">

                <g ><path class="cls-2" d="M443.81,1021.98v-154.58c0-3-2.44-5.44-5.44-5.44H161.37c-3,0-5.44-2.44-5.44-5.44v-369.83c0-3,2.44-5.44,5.44-5.44h195.63c3,0,5.44-2.44,5.44-5.44V45.7"/></g>
                <g ><path class="cls-4" d="M866.49,474.25v375.32c0,2.97-2.41,5.38-5.38,5.38l-403.36.1v170.42"/></g>
                <g ><path class="cls-1" d="M458.8,869.24h416.73c2.96,0,5.35-2.4,5.35-5.35v-375.92c0-2.93,2.35-5.31,5.28-5.35l115.43-1.47"/></g>
                <g onMouseEnter={() => setSelection("Ehrenwortplatz")} className='cursor-pointer'><circle class="cls-3 g" cx="450.81" cy="861.96" r="20"/></g>
                <g onMouseEnter={() => setSelection("Wagner Loch")} className='cursor-pointer'><circle class="cls-3 k" cx="155.93" cy="481.25" r="12"/></g>
                <g onMouseEnter={() => setSelection("Neue Stadthalle")} className='cursor-pointer'><circle class="cls-3 k" cx="450.81" cy="945.68" r="12"/></g>
                <g onMouseEnter={() => setSelection("U-Boot Bahnhof")} className='cursor-pointer'><circle class="cls-3 g" cx="873.49" cy="481.25" r="20"/></g>
                <g onMouseEnter={() => setSelection("Bunker")} className='cursor-pointer'><circle class="cls-3 k" cx="450.81" cy="1021.98" r="12"/></g>
                <g onMouseEnter={() => setSelection("Friesenstube")} className='cursor-pointer'><circle class="cls-3 k" cx="155.93" cy="794.71" r="12"/></g>
                <g onMouseEnter={() => setSelection("F'Groden")} className='cursor-pointer'><circle class="cls-3 g" cx="362.44" cy="45.7" r="20"/></g>
                <g>
                    <path class="cls-6" d="M389.45,811.55h7.2v3.12h-3.7v3.5h3.53v3.12h-3.53v4.27h3.7v3.12h-7.2v-17.14Z"/>
                    <path class="cls-6" d="M398.16,811.55h3.26v6.19h.05c.38-.94,1.34-1.78,2.57-1.78,1.49,0,2.57.89,2.57,2.98v9.75h-3.41v-8.83c0-.86-.26-1.2-.77-1.2-.6,0-.86.34-.86,1.44v8.59h-3.41v-17.14Z"/>
                    <path class="cls-6" d="M408.38,816.3h3.29v2.45h.05c.94-2.06,1.51-2.45,2.83-2.64v4.15h-.05c-.22-.05-.43-.07-.65-.07-1.15,0-2.06.79-2.06,2.28v6.22h-3.41v-12.38Z"/>
                    <path class="cls-6" d="M418.73,823.31v1.92c0,.82.38,1.1.91,1.1s.91-.29.91-1.1v-.67h3.12v1.1c0,2.21-1.27,3.36-4.18,3.36s-4.18-1.15-4.18-3.36v-5.54c0-2.83,1.06-4.15,4.18-4.15s4.18,1.32,4.18,4.15v3.19h-4.94ZM420.55,820.96v-1.13c0-.79-.36-1.18-.91-1.18s-.91.38-.91,1.18v1.13h1.82Z"/>
                    <path class="cls-6" d="M425.25,816.3h3.26v1.44h.05c.38-.94,1.34-1.78,2.57-1.78,1.49,0,2.57.89,2.57,2.98v9.75h-3.41v-8.83c0-.86-.26-1.2-.77-1.2-.6,0-.86.34-.86,1.44v8.59h-3.41v-12.38Z"/>
                    <path class="cls-6" d="M434.76,816.3h3.48l.94,9.26h.05l1.08-9.26h3.77l1.06,9.26h.05l.96-9.26h3.41l-2.33,12.38h-4.08l-.94-9.26h-.05l-.98,9.26h-4.08l-2.33-12.38Z"/>
                    <path class="cls-6" d="M450.4,819.68c0-2.66,1.78-3.72,4.2-3.72s4.2,1.06,4.2,3.72v5.62c0,2.67-1.78,3.72-4.2,3.72s-4.2-1.06-4.2-3.72v-5.62ZM453.81,825.28c0,.74.19,1.06.79,1.06s.79-.31.79-1.06v-5.57c0-.74-.19-1.06-.79-1.06s-.79.31-.79,1.06v5.57Z"/>
                    <path class="cls-6" d="M460.36,816.3h3.29v2.45h.05c.94-2.06,1.51-2.45,2.83-2.64v4.15h-.05c-.22-.05-.43-.07-.65-.07-1.15,0-2.06.79-2.06,2.28v6.22h-3.41v-12.38Z"/>
                    <path class="cls-6" d="M467.83,813.01h3.41v3.29h1.85v2.78h-1.85v5.04c0,1.34.14,1.54.91,1.54h.94v3.02h-3.36c-1.27,0-1.9-.82-1.9-2.11v-7.49h-1.08v-2.78h1.08v-3.29Z"/>
                    <path class="cls-6" d="M477.4,817.93h.05c.31-1.13,1.32-1.97,2.66-1.97,1.44,0,2.47,1.03,2.47,2.71v7.63c0,1.68-1.03,2.71-2.47,2.71s-2.28-.77-2.52-1.63h-.05v5.62h-3.41v-16.71h3.26v1.63ZM479.18,819.71c0-.74-.24-1.06-.72-1.06-.43,0-.91.34-.91,1.06v5.57c0,.72.48,1.06.91,1.06.48,0,.72-.31.72-1.06v-5.57Z"/>
                    <path class="cls-6" d="M487.84,811.55v17.14h-3.41v-17.14h3.41Z"/>
                    <path class="cls-6" d="M489.72,820.26v-1.18c0-1.97,1.44-3.12,4.01-3.12s4.01,1.15,4.01,3.12v7.25c0,1.1.22,1.75.38,2.35h-3.29c-.17-.41-.22-.89-.22-1.34h-.05c-.48,1.22-1.37,1.68-2.45,1.68-1.92,0-2.83-.98-2.83-3.55v-.72c0-5.06,5.18-2.81,5.18-5.38,0-.46-.22-.96-.77-.96-.86,0-1.01.5-1.01,1.85h-2.98ZM494.32,822.76c-1.03.72-1.63.84-1.63,2.23v.31c0,.74.36,1.03.98,1.03.26,0,.65-.24.65-.79v-2.78Z"/>
                    <path class="cls-6" d="M499.82,813.01h3.41v3.29h1.85v2.78h-1.85v5.04c0,1.34.14,1.54.91,1.54h.94v3.02h-3.36c-1.27,0-1.9-.82-1.9-2.11v-7.49h-1.08v-2.78h1.08v-3.29Z"/>
                    <path class="cls-6" d="M505.53,816.3h7.34v2.78l-3.46,6.82h3.55v2.78h-7.44v-2.78l3.46-6.82h-3.46v-2.78Z"/>
                </g>
                <g>
                    <path class="cls-6" d="M480.19,938.18h2.9v14.99h-3.84l-2.65-10.48h-.04v10.48h-2.9v-14.99h3.88l2.6,10.56h.04v-10.56Z"/>
                    <path class="cls-6" d="M487.62,948.47v1.68c0,.71.34.97.8.97s.8-.25.8-.97v-.59h2.73v.97c0,1.93-1.11,2.94-3.65,2.94s-3.65-1.01-3.65-2.94v-4.85c0-2.48.92-3.63,3.65-3.63s3.65,1.15,3.65,3.63v2.79h-4.33ZM489.22,946.42v-.99c0-.69-.31-1.03-.8-1.03s-.8.34-.8,1.03v.99h1.6Z"/>
                    <path class="cls-6" d="M500.73,953.18h-2.86v-1.26h-.04c-.34.82-1.18,1.55-2.25,1.55-1.3,0-2.25-.78-2.25-2.6v-8.52h2.98v7.73c0,.76.23,1.05.67,1.05.52,0,.76-.29.76-1.26v-7.52h2.98v10.83Z"/>
                    <path class="cls-6" d="M505.12,948.47v1.68c0,.71.34.97.8.97s.8-.25.8-.97v-.59h2.73v.97c0,1.93-1.11,2.94-3.65,2.94s-3.65-1.01-3.65-2.94v-4.85c0-2.48.92-3.63,3.65-3.63s3.65,1.15,3.65,3.63v2.79h-4.33ZM506.71,946.42v-.99c0-.69-.31-1.03-.8-1.03s-.8.34-.8,1.03v.99h1.6Z"/>
                    <path class="cls-6" d="M519.69,942.7v-.84c0-.86-.27-1.24-.88-1.24s-.88.36-.88,1.24c0,2.62,4.83,2.62,4.83,7.52,0,3.13-.97,4.09-3.97,4.09-2.79,0-3.93-.84-3.93-3.61v-1.41h3.07v1.05c0,.86.27,1.24.88,1.24s.88-.36.88-1.24c-.02-3.11-4.83-2.79-4.83-7.79,0-2.69,1.01-3.82,3.95-3.82,2.6,0,3.86.97,3.86,3.63v1.18h-2.98Z"/>
                    <path class="cls-6" d="M524.16,939.47h2.98v2.88h1.62v2.44h-1.62v4.41c0,1.18.13,1.34.8,1.34h.82v2.65h-2.94c-1.11,0-1.66-.71-1.66-1.85v-6.55h-.94v-2.44h.94v-2.88Z"/>
                    <path class="cls-6" d="M529.71,945.81v-1.03c0-1.72,1.26-2.73,3.51-2.73s3.51,1.01,3.51,2.73v6.34c0,.97.19,1.53.34,2.06h-2.88c-.15-.36-.19-.78-.19-1.18h-.04c-.42,1.07-1.2,1.47-2.14,1.47-1.68,0-2.48-.86-2.48-3.11v-.63c0-4.43,4.54-2.46,4.54-4.7,0-.4-.19-.84-.67-.84-.76,0-.88.44-.88,1.62h-2.6ZM533.74,947.99c-.9.63-1.43.74-1.43,1.95v.27c0,.65.31.9.86.9.23,0,.57-.21.57-.69v-2.44Z"/>
                    <path class="cls-6" d="M542.77,951.68c-.32,1.05-1.2,1.79-2.37,1.79-1.26,0-2.16-.9-2.16-2.37v-6.68c0-1.47.9-2.37,2.16-2.37s1.99.67,2.21,1.43h.04v-5.29h2.98v14.99h-2.86v-1.49ZM541.21,950.19c0,.65.21.92.63.92.38,0,.8-.29.8-.92v-4.87c0-.63-.42-.92-.8-.92-.42,0-.63.27-.63.92v4.87Z"/>
                    <path class="cls-6" d="M547.5,939.47h2.98v2.88h1.62v2.44h-1.62v4.41c0,1.18.13,1.34.8,1.34h.82v2.65h-2.94c-1.11,0-1.66-.71-1.66-1.85v-6.55h-.94v-2.44h.94v-2.88Z"/>
                    <path class="cls-6" d="M553.02,938.18h2.86v5.42h.04c.34-.82,1.18-1.55,2.25-1.55,1.3,0,2.25.78,2.25,2.6v8.52h-2.98v-7.73c0-.76-.23-1.05-.67-1.05-.53,0-.76.29-.76,1.26v7.52h-2.98v-14.99Z"/>
                    <path class="cls-6" d="M561.98,945.81v-1.03c0-1.72,1.26-2.73,3.51-2.73s3.51,1.01,3.51,2.73v6.34c0,.97.19,1.53.34,2.06h-2.88c-.15-.36-.19-.78-.19-1.18h-.04c-.42,1.07-1.2,1.47-2.14,1.47-1.68,0-2.48-.86-2.48-3.11v-.63c0-4.43,4.54-2.46,4.54-4.7,0-.4-.19-.84-.67-.84-.76,0-.88.44-.88,1.62h-2.6ZM566.02,947.99c-.9.63-1.43.74-1.43,1.95v.27c0,.65.31.9.86.9.23,0,.57-.21.57-.69v-2.44Z"/>
                    <path class="cls-6" d="M573.56,938.18v14.99h-2.98v-14.99h2.98Z"/>
                    <path class="cls-6" d="M578.22,938.18v14.99h-2.98v-14.99h2.98Z"/>
                    <path class="cls-6" d="M582.67,948.47v1.68c0,.71.34.97.8.97s.8-.25.8-.97v-.59h2.73v.97c0,1.93-1.11,2.94-3.65,2.94s-3.65-1.01-3.65-2.94v-4.85c0-2.48.92-3.63,3.65-3.63s3.65,1.15,3.65,3.63v2.79h-4.33ZM584.26,946.42v-.99c0-.69-.32-1.03-.8-1.03s-.8.34-.8,1.03v.99h1.6Z"/>
                </g>
                <g>
                    <path class="cls-6" d="M474.58,1015.21h4.47c2.37,0,3.34,1.05,3.34,3.07v1.3c0,1.13-.38,2.27-1.85,2.73v.04c1.34.21,1.93,1.05,1.93,2.54v2.25c0,2.02-.97,3.07-3.34,3.07h-4.56v-14.99ZM477.65,1021.01h.78c.69,0,.9-.21.9-1.53s-.21-1.53-.9-1.53h-.78v3.07ZM477.65,1027.47h.71c.88,0,1.01-.21,1.05-1.87,0-1.7-.17-1.87-1.05-1.87h-.71v3.74Z"/>
                    <path class="cls-6" d="M491.15,1030.2h-2.86v-1.26h-.04c-.34.82-1.18,1.55-2.25,1.55-1.3,0-2.25-.78-2.25-2.6v-8.52h2.98v7.73c0,.76.23,1.05.67,1.05.52,0,.76-.29.76-1.26v-7.52h2.98v10.83Z"/>
                    <path class="cls-6" d="M492.71,1019.37h2.86v1.26h.04c.34-.82,1.18-1.55,2.25-1.55,1.3,0,2.25.78,2.25,2.6v8.52h-2.98v-7.73c0-.76-.23-1.05-.67-1.05-.52,0-.76.29-.76,1.26v7.52h-2.98v-10.83Z"/>
                    <path class="cls-6" d="M501.65,1015.21h2.98v9.32l.04.02,1.55-5.19h2.96l-1.68,5.14,1.76,5.69h-2.96l-1.64-5.5h-.04v5.5h-2.98v-14.99Z"/>
                    <path class="cls-6" d="M513.03,1025.5v1.68c0,.71.34.97.8.97s.8-.25.8-.97v-.59h2.73v.97c0,1.93-1.11,2.94-3.65,2.94s-3.65-1.01-3.65-2.94v-4.85c0-2.48.92-3.63,3.65-3.63s3.65,1.15,3.65,3.63v2.79h-4.33ZM514.63,1023.44v-.99c0-.69-.31-1.03-.8-1.03s-.8.34-.8,1.03v.99h1.6Z"/>
                    <path class="cls-6" d="M518.75,1019.37h2.88v2.14h.04c.82-1.81,1.32-2.14,2.48-2.31v3.63h-.04c-.19-.04-.38-.06-.57-.06-1.01,0-1.81.69-1.81,1.99v5.44h-2.98v-10.83Z"/>
                </g>
                <g>
                    <path class="cls-6" d="M179.12,787.24h6.3v2.73h-3.23v3.07h3.09v2.73h-3.09v6.47h-3.07v-14.99Z"/>
                    <path class="cls-6" d="M186.74,791.39h2.88v2.14h.04c.82-1.81,1.32-2.14,2.48-2.31v3.63h-.04c-.19-.04-.38-.06-.57-.06-1.01,0-1.81.69-1.81,1.99v5.44h-2.98v-10.83Z"/>
                    <path class="cls-6" d="M196,791.39v10.83h-2.98v-10.83h2.98ZM193.14,790.09v-2.86h2.73v2.86h-2.73Z"/>
                    <path class="cls-6" d="M200.45,797.52v1.68c0,.71.34.97.8.97s.8-.25.8-.97v-.59h2.73v.97c0,1.93-1.11,2.94-3.65,2.94s-3.65-1.01-3.65-2.94v-4.85c0-2.48.92-3.63,3.65-3.63s3.65,1.15,3.65,3.63v2.79h-4.33ZM202.05,795.47v-.99c0-.69-.31-1.03-.8-1.03s-.8.34-.8,1.03v.99h1.6Z"/>
                    <path class="cls-6" d="M210.13,794.84v-.67c0-.44-.34-.71-.73-.71-.46,0-.69.25-.69.65,0,1.79,4.41,1.43,4.41,4.81.1,2.39-1.03,3.61-3.63,3.61s-3.63-1.22-3.63-3.19v-.84h2.86v.46c0,.94.31,1.22.78,1.22s.65-.25.65-.86c0-2.18-4.28-1.15-4.28-5.27,0-1.74,1.15-2.94,3.63-2.94,2.2,0,3.38.9,3.38,2.73v1.01h-2.73Z"/>
                    <path class="cls-6" d="M217.17,797.52v1.68c0,.71.34.97.8.97s.8-.25.8-.97v-.59h2.73v.97c0,1.93-1.11,2.94-3.65,2.94s-3.65-1.01-3.65-2.94v-4.85c0-2.48.92-3.63,3.65-3.63s3.65,1.15,3.65,3.63v2.79h-4.33ZM218.76,795.47v-.99c0-.69-.31-1.03-.8-1.03s-.8.34-.8,1.03v.99h1.6Z"/>
                    <path class="cls-6" d="M222.88,791.39h2.86v1.26h.04c.34-.82,1.18-1.55,2.25-1.55,1.3,0,2.25.78,2.25,2.6v8.52h-2.98v-7.73c0-.76-.23-1.05-.67-1.05-.52,0-.76.29-.76,1.26v7.52h-2.98v-10.83Z"/>
                    <path class="cls-6" d="M235.79,794.84v-.67c0-.44-.34-.71-.73-.71-.46,0-.69.25-.69.65,0,1.79,4.41,1.43,4.41,4.81.1,2.39-1.03,3.61-3.63,3.61s-3.63-1.22-3.63-3.19v-.84h2.86v.46c0,.94.31,1.22.78,1.22s.65-.25.65-.86c0-2.18-4.28-1.15-4.28-5.27,0-1.74,1.15-2.94,3.63-2.94,2.2,0,3.38.9,3.38,2.73v1.01h-2.73Z"/>
                    <path class="cls-6" d="M240.31,788.52h2.98v2.88h1.62v2.44h-1.62v4.41c0,1.18.13,1.34.8,1.34h.82v2.65h-2.94c-1.11,0-1.66-.71-1.66-1.85v-6.55h-.94v-2.44h.94v-2.88Z"/>
                    <path class="cls-6" d="M253.22,802.23h-2.86v-1.26h-.04c-.34.82-1.18,1.55-2.25,1.55-1.3,0-2.25-.78-2.25-2.6v-8.52h2.98v7.73c0,.76.23,1.05.67,1.05.52,0,.76-.29.76-1.26v-7.52h2.98v10.83Z"/>
                    <path class="cls-6" d="M257.76,787.24v5.29h.04c.21-.76.96-1.43,2.2-1.43s2.16.9,2.16,2.37v6.68c0,1.47-.9,2.37-2.16,2.37-1.18,0-2.06-.73-2.33-1.72h-.04v1.43h-2.86v-14.99h2.98ZM259.19,794.38c0-.65-.21-.92-.63-.92-.38,0-.8.29-.8.92v4.87c0,.63.42.92.8.92.42,0,.63-.27.63-.92v-4.87Z"/>
                    <path class="cls-6" d="M266.56,797.52v1.68c0,.71.34.97.8.97s.8-.25.8-.97v-.59h2.73v.97c0,1.93-1.11,2.94-3.65,2.94s-3.65-1.01-3.65-2.94v-4.85c0-2.48.92-3.63,3.65-3.63s3.65,1.15,3.65,3.63v2.79h-4.33ZM268.15,795.47v-.99c0-.69-.31-1.03-.8-1.03s-.8.34-.8,1.03v.99h1.6Z"/>
                </g>
                <g>
                    <path class="cls-6" d="M107.96,445.95h3.17l1.24,11.72h.04l1.32-11.72h3.44l1.36,11.74h.04l1.2-11.74h3.17l-2.31,14.99h-3.86l-1.3-11.46h-.04l-1.3,11.46h-3.86l-2.31-14.99Z"/>
                    <path class="cls-6" d="M123.46,453.57v-1.03c0-1.72,1.26-2.73,3.51-2.73s3.51,1.01,3.51,2.73v6.34c0,.97.19,1.53.34,2.06h-2.88c-.15-.36-.19-.78-.19-1.18h-.04c-.42,1.07-1.2,1.47-2.14,1.47-1.68,0-2.48-.86-2.48-3.11v-.63c0-4.43,4.54-2.46,4.54-4.7,0-.4-.19-.84-.67-.84-.76,0-.88.44-.88,1.62h-2.6ZM127.49,455.75c-.9.63-1.43.74-1.43,1.95v.27c0,.65.31.9.86.9.23,0,.57-.21.57-.69v-2.44Z"/>
                    <path class="cls-6" d="M134.96,461.65c0,.63.34.88.71.88.46,0,.71-.34.71-.9v-1.91h-.04c-.31.5-.97,1.22-2.18,1.22-1.13,0-2.18-.76-2.18-2.39v-6.34c0-1.64,1.05-2.39,2.18-2.39,1.39,0,2.2.99,2.31,1.55h.04v-1.26h2.86v11.17c0,2.6-1.13,3.44-3.65,3.44-2.73,0-3.61-.67-3.61-3.07h2.86ZM134.96,457.66c0,.65.21.92.63.92.38,0,.8-.29.8-.92v-4.58c0-.63-.42-.92-.8-.92-.42,0-.63.27-.63.92v4.58Z"/>
                    <path class="cls-6" d="M140.93,450.1h2.86v1.26h.04c.34-.82,1.18-1.55,2.25-1.55,1.3,0,2.25.78,2.25,2.6v8.52h-2.98v-7.73c0-.76-.23-1.05-.67-1.05-.52,0-.76.29-.76,1.26v7.52h-2.98v-10.83Z"/>
                    <path class="cls-6" d="M152.71,456.24v1.68c0,.71.34.97.8.97s.8-.25.8-.97v-.59h2.73v.97c0,1.93-1.11,2.94-3.65,2.94s-3.65-1.01-3.65-2.94v-4.85c0-2.48.92-3.63,3.65-3.63s3.65,1.15,3.65,3.63v2.79h-4.33ZM154.31,454.18v-.99c0-.69-.31-1.03-.8-1.03s-.8.34-.8,1.03v.99h1.6Z"/>
                    <path class="cls-6" d="M158.42,450.1h2.88v2.14h.04c.82-1.81,1.32-2.14,2.48-2.31v3.63h-.04c-.19-.04-.38-.06-.57-.06-1.01,0-1.81.69-1.81,1.99v5.44h-2.98v-10.83Z"/>
                    <path class="cls-6" d="M164.74,456.78v-2.52h4.85v2.52h-4.85Z"/>
                    <path class="cls-6" d="M171.4,460.94v-14.99h3.07v12.26h3.23v2.73h-6.3Z"/>
                    <path class="cls-6" d="M178.48,453.06c0-2.33,1.55-3.25,3.67-3.25s3.67.92,3.67,3.25v4.91c0,2.33-1.55,3.25-3.67,3.25s-3.67-.92-3.67-3.25v-4.91ZM181.46,457.96c0,.65.17.92.69.92s.69-.27.69-.92v-4.87c0-.65-.17-.92-.69-.92s-.69.27-.69.92v4.87Z"/>
                    <path class="cls-6" d="M194.08,456.61v1.62c0,2.12-1.32,3-3.59,3s-3.55-1.07-3.55-3v-5.42c0-1.91,1.22-3,3.55-3s3.59.9,3.59,3v1.41h-2.73v-1.22c0-.53-.27-.84-.71-.84s-.71.31-.71.84v5.04c0,.53.27.84.71.84s.71-.31.71-.84v-1.43h2.73Z"/>
                    <path class="cls-6" d="M195.36,445.95h2.86v5.42h.04c.34-.82,1.18-1.55,2.25-1.55,1.3,0,2.25.78,2.25,2.6v8.52h-2.98v-7.73c0-.76-.23-1.05-.67-1.05-.52,0-.76.29-.76,1.26v7.52h-2.98v-14.99Z"/>
                </g>
                <g>
                    <path class="cls-6" d="M396.21,36.3h7.2v3.12h-3.7v3.5h3.53v3.12h-3.53v7.39h-3.5v-17.14Z"/>
                    <path class="cls-6" d="M405.04,42.54v-3.12l1.61-3.12h1.75l-1.44,3.12h1.2v3.12h-3.12Z"/>
                    <path class="cls-6" d="M418.99,53.44h-2.28l-.5-1.25c-.72,1.06-1.32,1.58-2.83,1.58-2.04,0-3.41-1.2-3.41-3.38v-10.39c0-2.83,1.22-4.03,4.42-4.03,3.38,0,4.61,1.2,4.61,4.03v1.06h-3.5v-1.03c0-.67-.29-.94-1.01-.94s-1.01.26-1.01.94v9.7c0,.67.29.94,1.01.94s1.01-.26,1.01-.94v-3.67h-1.03v-3.12h4.54v10.51Z"/>
                    <path class="cls-6" d="M420.69,41.05h3.29v2.45h.05c.94-2.06,1.51-2.45,2.83-2.64v4.15h-.05c-.22-.05-.43-.07-.65-.07-1.15,0-2.06.79-2.06,2.28v6.22h-3.41v-12.38Z"/>
                    <path class="cls-6" d="M427.6,44.44c0-2.66,1.78-3.72,4.2-3.72s4.2,1.06,4.2,3.72v5.62c0,2.67-1.78,3.72-4.2,3.72s-4.2-1.06-4.2-3.72v-5.62ZM431.01,50.03c0,.74.19,1.06.79,1.06s.79-.31.79-1.06v-5.57c0-.74-.19-1.06-.79-1.06s-.79.31-.79,1.06v5.57Z"/>
                    <path class="cls-6" d="M442.75,51.73c-.36,1.2-1.37,2.04-2.71,2.04-1.44,0-2.47-1.03-2.47-2.71v-7.63c0-1.68,1.03-2.71,2.47-2.71s2.28.77,2.52,1.63h.05v-6.05h3.41v17.14h-3.26v-1.7ZM440.97,50.03c0,.74.24,1.06.72,1.06.43,0,.91-.34.91-1.06v-5.57c0-.72-.48-1.06-.91-1.06-.48,0-.72.31-.72,1.06v5.57Z"/>
                    <path class="cls-6" d="M451.03,48.06v1.92c0,.82.38,1.1.91,1.1s.91-.29.91-1.1v-.67h3.12v1.1c0,2.21-1.27,3.36-4.18,3.36s-4.18-1.15-4.18-3.36v-5.54c0-2.83,1.06-4.15,4.18-4.15s4.18,1.32,4.18,4.15v3.19h-4.94ZM452.85,45.71v-1.13c0-.79-.36-1.18-.91-1.18s-.91.38-.91,1.18v1.13h1.82Z"/>
                    <path class="cls-6" d="M457.56,41.05h3.26v1.44h.05c.38-.94,1.34-1.78,2.57-1.78,1.49,0,2.57.89,2.57,2.98v9.75h-3.41v-8.83c0-.86-.26-1.2-.77-1.2-.6,0-.86.34-.86,1.44v8.59h-3.41v-12.38Z"/>
                </g>
                
                <rect class="cls-7" x="312.7" y="219.62" width="105.03" height="70.02"/>
                <g onMouseEnter={() => setSelection("Ewige Baustelle")} className='cursor-pointer'>
                    <g>
                    <rect class="cls-7" x="320" y="219.62" width="85" height="70.02"/>
                    <path class="cls-5" d="M355.85,264.87c0-.2-.07-.4-.18-.57l-4.44-6.86-6.95-3.68-10.53,19.89c-.34.65-.52,1.37-.52,2.1,0,1.67.92,3.19,2.39,3.97l11.11-20.98,4.67,7.22.35,9.44c.09,2.41,2.07,4.33,4.49,4.33.08,0,.09,0,.17,0l-.55-14.85M346.57,248.23l-.89,1.68-2.65-2.19-1.19,2.25c-1.29-.68-2.09-2.02-2.09-3.48,0-.64.16-1.27.46-1.84l3.24-6.11c.49-.92,1.44-1.5,2.48-1.5h9.91c2.48,0,4.49,2.01,4.49,4.5,0,.1,0,.19,0,.29l-1.1,16.86,10.54,8.77c-.41.48-.93.86-1.51,1.1l-9.18-7.58-.12,1.91c-2.07-.13-3.68-1.85-3.68-3.92,0-.09,0-.17,0-.25l.05-.82-2.58-2.13.89-1.67,1.84,1.52.45-7.04-4.18,7.9-6.95-3.68,6.24-11.79h-4.45l-2.68,5.07,2.65,2.19h0ZM363.7,230.58c-2.33,0-4.21,1.89-4.21,4.21s1.89,4.21,4.21,4.21,4.21-1.89,4.21-4.21c0-2.33-1.89-4.21-4.21-4.21ZM368.14,269.19c.8-.26,1.51-.74,2.05-1.38l4.38-5.19c1.07-1.26,2.64-1.99,4.29-1.99,1.75,0,3.39.81,4.46,2.19l11.6,15.1c.15.2.23.44.23.68,0,.62-.5,1.12-1.12,1.12h-33.71c-.62,0-1.12-.5-1.12-1.12,0-.27.09-.52.26-.72l6.44-7.62c.27-.32.63-.56,1.03-.69l1.2-.38h0Z"/>
                    </g>
                </g>
                <g onMouseEnter={() => setSelection("Tunnel durch den Jadebusen")} className='cursor-pointer'>
                    <g>
                    <rect class="cls-7" x="1000" y="445" width="65" height="70.02"/>
                    <path class="cls-5" d="M1025.24,491.34v-10.25c0-4.14,3.4-7.54,7.54-7.54s7.54,3.4,7.54,7.54v10.25h-15.08ZM1033.31,457.25c10.94,0,19.86,8.87,19.86,19.86v25.59h5.63v-25.59c0-14.07-11.42-25.49-25.49-25.49s-25.49,11.42-25.49,25.49v25.59h5.63v-25.59c0-10.99,8.92-19.86,19.86-19.86M1034.32,493.52h-3.08l-.37,2.18h3.82l-.37-2.18ZM1035.22,498.99h-4.94l-.58,3.24h6.11l-.58-3.24ZM1036.55,506.58h-7.59l-.69,4.3h9.03l-.74-4.3Z"/>
                    </g>
                </g>
                <g>
                    <path class="cls-6" d="M819.47,446.53c0,3.22-1.44,4.03-4.51,4.03s-4.51-.82-4.51-4.03v-13.44h3.5v12.91c0,1.2.29,1.44,1.01,1.44s1.01-.24,1.01-1.44v-12.91h3.5v13.44Z"/>
                    <path class="cls-6" d="M821.29,445.48v-2.88h5.54v2.88h-5.54Z"/>
                    <path class="cls-6" d="M828.9,433.09h5.11c2.71,0,3.82,1.2,3.82,3.5v1.49c0,1.3-.43,2.59-2.11,3.12v.05c1.54.24,2.21,1.2,2.21,2.9v2.57c0,2.3-1.1,3.5-3.82,3.5h-5.21v-17.14ZM832.41,439.72h.89c.79,0,1.03-.24,1.03-1.75s-.24-1.75-1.03-1.75h-.89v3.5ZM832.41,447.11h.82c1.01,0,1.15-.24,1.2-2.14,0-1.94-.19-2.14-1.2-2.14h-.82v4.27Z"/>
                    <path class="cls-6" d="M839.2,441.23c0-2.66,1.78-3.72,4.2-3.72s4.2,1.06,4.2,3.72v5.62c0,2.67-1.78,3.72-4.2,3.72s-4.2-1.06-4.2-3.72v-5.62ZM842.61,446.82c0,.74.19,1.06.79,1.06s.79-.31.79-1.06v-5.57c0-.74-.19-1.06-.79-1.06s-.79.31-.79,1.06v5.57Z"/>
                    <path class="cls-6" d="M848.96,441.23c0-2.66,1.78-3.72,4.2-3.72s4.2,1.06,4.2,3.72v5.62c0,2.67-1.78,3.72-4.2,3.72s-4.2-1.06-4.2-3.72v-5.62ZM852.37,446.82c0,.74.19,1.06.79,1.06s.79-.31.79-1.06v-5.57c0-.74-.19-1.06-.79-1.06s-.79.31-.79,1.06v5.57Z"/>
                    <path class="cls-6" d="M859.28,434.55h3.41v3.29h1.85v2.78h-1.85v5.04c0,1.34.14,1.54.91,1.54h.94v3.02h-3.36c-1.27,0-1.9-.82-1.9-2.11v-7.49h-1.08v-2.78h1.08v-3.29Z"/>
                    <path class="cls-6" d="M870.88,433.09h5.11c2.71,0,3.82,1.2,3.82,3.5v1.49c0,1.3-.43,2.59-2.11,3.12v.05c1.54.24,2.21,1.2,2.21,2.9v2.57c0,2.3-1.1,3.5-3.82,3.5h-5.21v-17.14ZM874.38,439.72h.89c.79,0,1.03-.24,1.03-1.75s-.24-1.75-1.03-1.75h-.89v3.5ZM874.38,447.11h.82c1.01,0,1.15-.24,1.2-2.14,0-1.94-.19-2.14-1.2-2.14h-.82v4.27Z"/>
                    <path class="cls-6" d="M881.39,441.8v-1.18c0-1.97,1.44-3.12,4.01-3.12s4.01,1.15,4.01,3.12v7.25c0,1.1.22,1.75.38,2.35h-3.29c-.17-.41-.22-.89-.22-1.34h-.05c-.48,1.22-1.37,1.68-2.45,1.68-1.92,0-2.83-.98-2.83-3.55v-.72c0-5.06,5.18-2.81,5.18-5.38,0-.46-.22-.96-.77-.96-.86,0-1.01.5-1.01,1.85h-2.98ZM886,444.3c-1.03.72-1.63.84-1.63,2.23v.31c0,.74.36,1.03.98,1.03.26,0,.65-.24.65-.79v-2.78Z"/>
                    <path class="cls-6" d="M891.13,433.09h3.26v6.19h.05c.38-.94,1.34-1.78,2.57-1.78,1.49,0,2.57.89,2.57,2.98v9.75h-3.41v-8.83c0-.86-.26-1.2-.77-1.2-.6,0-.86.34-.86,1.44v8.59h-3.41v-17.14Z"/>
                    <path class="cls-6" d="M901.36,437.84h3.26v1.44h.05c.38-.94,1.34-1.78,2.57-1.78,1.49,0,2.57.89,2.57,2.98v9.75h-3.41v-8.83c0-.86-.26-1.2-.77-1.2-.6,0-.86.34-.86,1.44v8.59h-3.41v-12.38Z"/>
                    <path class="cls-6" d="M911.58,433.09h3.26v6.19h.05c.38-.94,1.34-1.78,2.57-1.78,1.49,0,2.57.89,2.57,2.98v9.75h-3.41v-8.83c0-.86-.26-1.2-.77-1.2-.6,0-.86.34-.86,1.44v8.59h-3.41v-17.14Z"/>
                    <path class="cls-6" d="M921.61,441.23c0-2.66,1.78-3.72,4.2-3.72s4.2,1.06,4.2,3.72v5.62c0,2.67-1.78,3.72-4.2,3.72s-4.2-1.06-4.2-3.72v-5.62ZM925.02,446.82c0,.74.19,1.06.79,1.06s.79-.31.79-1.06v-5.57c0-.74-.19-1.06-.79-1.06s-.79.31-.79,1.06v5.57Z"/>
                    <path class="cls-6" d="M935.41,440.63v9.6h-3.41v-9.6h-1.18v-2.78h1.18v-1.56c0-2.33,1.08-3.19,3.19-3.19h2.02v2.78h-.79c-.7,0-1.01.22-1.01.91v1.06h1.8v2.78h-1.8Z"/>
                </g>
                
                </svg>
                </Col>
                <Col md={6} className='pt-5 ps-4' style={{backgroundColor: "#ebebeb", marginTop: "-4em", zIndex: "-1"}}>
                    <h1>{selection}</h1>
                    {content.find(station => station.name === selection).img ? 
                        <>
                        <img src={content.find(station => station.name === selection).img} style={{width: "400px", maxWidth: "90%"}} className='rounded mt-3 shadow '/>
                        </>
                    : null}
                    <p className='mt-3'>{content.find(station => station.name === selection).text}</p>
                </Col> 
            </Row>

    </div>
  );
}
